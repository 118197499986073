.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


.App-body {
  /* background-color: #123; */
  min-height:80vh;
  display: flex;
  margin: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-box {

  padding: 50px;
  border-radius: 11px;
  box-shadow:  20px 20px 60px #22252c,
             -20px -20px 60px #2e333c;
  box-shadow:
  11.6px 11.6px 1.2px rgba(0, 0, 0, 0.015),
  3.4px 3.4px 2.7px rgba(0, 0, 0, 0.022),
  5.8px 5.8px 4.6px rgba(0, 0, 0, 0.027),
  8.7px 8.7px 6.9px rgba(0, 0, 0, 0.031),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  17.7px 17.7px 14.2px rgba(0, 0, 0, 0.039),
  25.1px 25.1px 20.1px rgba(0, 0, 0, 0.043),
  36.5px 36.5px 29.2px rgba(0, 0, 0, 0.048),
  56.3px 56.3px 45px rgba(0, 0, 0, 0.055),
  100px 100px 80px rgba(0, 0, 0, 0.07)
;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
