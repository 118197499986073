@tailwind base;
@tailwind components;
@tailwind utilities;
img, svg, video, canvas, audio, iframe, embed, object {
  display: initial;
}
body {
  text-align: center;
  background-image: url('https://wallpapercave.com/wp/wp7707840.png');
  height: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

invert {
  transform: scaleX(-1);
}
